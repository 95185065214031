import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47')
];

export const server_loads = [0,8,10,11,3,4,7];

export const dictionary = {
		"/(default)": [~20,[5],[6]],
		"/admin": [~27,[8],[9]],
		"/admin/config": [~28,[8],[9]],
		"/admin/config/assinaturas": [~29,[8],[9]],
		"/admin/config/prioridades": [~30,[8],[9]],
		"/admin/config/tipos/[typeName=type]": [31,[8,10],[9]],
		"/admin/config/tipos/[typeName=type]/adicionar": [~32,[8,10],[9]],
		"/admin/config/tipos/[typeName=type]/editar/[id=integer]/[name]": [~33,[8,10],[9]],
		"/admin/eventos": [~34,[8],[9]],
		"/admin/eventos/cadastro": [~43,[8],[9]],
		"/admin/eventos/[id=integer]/certificacao/organizador": [~35,[8,11],[9]],
		"/admin/eventos/[id=integer]/certificacao/palestrante": [~36,[8,11],[9]],
		"/admin/eventos/[id=integer]/credenciamento": [~37,[8,11],[9]],
		"/admin/eventos/[id=integer]/custos/relatorio": [~38,[8,11],[9]],
		"/admin/eventos/[id=integer]/editar": [~39,[8,11],[9]],
		"/admin/eventos/[id=integer]/gerenciar": [~40,[8,11],[9]],
		"/admin/eventos/[id=integer]/importar": [~41,[8,11],[9]],
		"/admin/eventos/[id=integer]/inscritos/adicionar": [~42,[8,11],[9]],
		"/admin/relatorios": [~44,[8],[9]],
		"/admin/talentos": [~45,[8],[9]],
		"/admin/usuarios": [~46,[8],[9]],
		"/admin/usuarios/gerenciar/[id=integer]": [~47,[8],[9]],
		"/(auth)/(unlogged)/cadastro": [~12,[2,3]],
		"/(auth)/cadastro/finalizar": [~16,[2]],
		"/(auth)/email/alterar": [~18,[2,4]],
		"/(auth)/email/[[email=email]]/verificar": [~17,[2,4]],
		"/(default)/eventos": [~24,[5],[6]],
		"/(default)/eventos/categorias/[name]": [~26,[5],[6]],
		"/(default)/eventos/[id=integer]": [~25,[5],[6]],
		"/(default)/(logged)/favoritos": [~21,[5,7],[6]],
		"/(auth)/(unlogged)/login": [~13,[2,3]],
		"/(default)/(logged)/meus-eventos": [~22,[5,7],[6]],
		"/(auth)/(unlogged)/nova-senha": [~14,[2,3]],
		"/(default)/(logged)/perfil": [~23,[5,7],[6]],
		"/(auth)/(unlogged)/recuperar-senha": [~15,[2,3]],
		"/(auth)/sair": [~19,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';